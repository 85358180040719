<template>
<section class="addGood-wrapper">
    <div class="addGood-title">
        <h1>基本信息</h1>
    </div>
    <!-- form -->
    <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
        <el-form-item label="商品名称" prop="productName">
            <el-input v-model="form.productName" style="width:670px;" type="text" placeholder="最多输入30个字符" maxlength="30"></el-input>
        </el-form-item>
        <el-form-item label="商品描述" prop="description">
            <el-input v-model="form.description" style="width:670px;" resize="none" type="textarea" placeholder="最多输入60个字符" maxlength="60"></el-input>
        </el-form-item>
        <el-form-item label="商品封面图" prop="mainImg">
            <el-upload
                action=""
                :http-request="uploadHandler1"
                :show-file-list="false"
                :limit="1"
                :before-upload="beforeUploadHandler"
                :file-list="mainImgList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1">
                <div class="el-upload--picture-card" >
                    <p class="upload-box upload-custom flex flex-column align-center flex-center" v-if="mainImgList.length==0">
                        <i class="el-icon-plus el-icon"></i><br/>
                        <span >添加上传图片</span>
                    </p>
                    <img v-else class="upload-img" :src="mainImgList[0].url" alt=""> 
                </div>
            </el-upload>
        </el-form-item>
        <el-form-item label="商品主图" prop="imgs">
            <p class="tip">建议主图的尺寸：750*750，支持png，jpeg，jpg格式，最多可上传4张；主图大小不能超过3MB。</p>
            <el-upload
                action=""
                :http-request="uploadHandler2"
                list-type="picture-card"
                :limit="4"
                :before-upload="beforeUploadHandler"
                :file-list="imgList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2">
                <p class="upload-custom flex flex-column align-center flex-center">
                    <i class="el-icon-plus el-icon"></i><br/>
                    <span >添加上传图片</span>
                </p>
            </el-upload>
        </el-form-item>
        <el-form-item label="商品详情" prop="detail">
            <div class="richText-box">
                <richText v-model="form.detail"></richText>
            </div>
        </el-form-item>
        <div class="addGood-title">
            <h1 class="i-b">基本信息</h1>
            <el-radio-group v-model="skuType">
                <el-radio :label="1">统一规格</el-radio>
                <el-radio :label="2">多规格</el-radio>
            </el-radio-group>
        </div>

        <!-- 统一规格 -->
        <div class="standardized" v-show="skuType==1">
            <el-form-item label="销售价格" prop="price">
                <el-input v-model="price" style="width:260px;margin: 0 5px;" type="number"></el-input>元
            </el-form-item>
            <el-form-item label="原价格" prop="oriPrice">
                <el-input v-model="oriPrice" style="width:260px;margin: 0 5px;" type="number"></el-input>元
            </el-form-item>
            <el-form-item label="商品库存" prop="quantity">
                <el-input v-model="quantity" style="width:260px;margin: 0 5px;" type="number"></el-input>件
            </el-form-item>
            <el-form-item label="商品编号" >
                <el-input v-model="productCode" style="width:260px;margin: 0 5px;" type="text"></el-input>
            </el-form-item>
            <el-form-item label="商品缩略图" >
                <label for="thumb" v-show="!iconUrl.thumb">
                    <span class="thumb-upload">上传</span>
                    <input id="thumb" type="file" accept="image/*" style="display:none;" @change="thumbUploadHandler">
                </label>
                <div class="thumb-box" v-show="iconUrl.thumb">
                    <img class="thumb" :src="iconUrl.thumb" alt="">
                    <span class="thumb-del" @click="delThumbHandler">删除</span>
                </div>
            </el-form-item> 
        </div>
        <!-- 多规格 -->
        <div class="sku-form" v-show="skuType==2">
            <el-form-item label="商品规则" prop="skuInfo"> 
                <sku-form ref="skuform" :skuinfo="form.skuInfo" 
                    @skuInfoChange="skuInfoChangeHandler"
                    ></sku-form>
            </el-form-item>
            <el-form-item label="销售明细" prop="skuList"> 
                <sku-list ref="skulist" :skuinfo="form.skuInfo" :skulist.sync="form.skuList"></sku-list>
            </el-form-item>
        </div>
        <el-form-item label="运费设置" prop="express">
            <el-radio-group @change="expressChangeHandler" v-model="form.delivery.isPostFree">
                <el-radio :label="true">免邮费</el-radio>
                <el-radio :label="false">
                    <span>统一邮费</span>
                    <el-input style="width:97px; margin: 0 5px;" type="number" v-model="form.delivery.deliveryInfo.express.price"></el-input>元
                    </el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>

    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <footer class="footer">
        <el-button @click="cancelHandler">取消</el-button>
        <el-button type="primary" @click="saveHandler">修 改</el-button>
    </footer>
</section>
</template>

<script>
import richText from '@/components/richText.vue';
import skuForm from '@/components/skuForm.vue';
import skuList from '@/components/skuList.vue';
import utils from '@/utils/utils.js';

let SkuList = [];
export default {
    data(){
        return {
            form:{
                detail:[],
                productName:'',
                description:'',
                mainImg:'',
                img:[],
                skuInfo:[],
                skuList:[],
                status:2, // 下架
                delivery:{
                    isPostFree: false,
                    deliveryInfo:{
                        deliveryType:0,
                        templateId:'',
                        express:{
                            deliveryName:'',
                            price:''
                        }
                    }
                },
            },
            formRules:{
                productName:[{required: true, message: '请输入商品名称', trigger: 'blur'}],
                description:[{required: true, message: '请输入商品描述', trigger: 'blur'}],
                mainImg:[{required: true, validator: this.mainImgValidator, trigger: 'change'}],
                imgs:[{required: true, validator: this.imgsValidator, trigger: 'change'}],
                detail:[{required: true, validator: this.detailValidator, trigger: 'change'}],
                price:[{required: true, validator: this.priceValidator, trigger: 'blur'}],
                quantity:[{required: true, validator: this.quantityValidator, trigger: 'blur'}],
                oriPrice:[{required: true, validator: this.oriPriceValidator, trigger: 'blur'}],
                skuInfo:[{required: true, validator: this.skuInfoValidator, trigger: 'change'}],
                skuList:[{required: true, validator: this.skuListValidator, trigger: 'change'}],
                express:[{required: true, validator: this.expressValidator, trigger: 'change'}]
            },
            imgList:[], // 主图列表
            mainImgList:[], // 封面图列表
            skuType:'1', // 商品规格类型： 1. 统一规格 2. 多规格
            price:'',
            quantity:'',
            oriPrice:'',
            productCode:'',
            iconUrl:{},
            dialogVisible: false,
            dialogImageUrl:'',
            id:0,
        }
    },
    components:{
        richText,
        skuForm,
        skuList,
    },
    created(){
        this.id = this.$route.query.id;
        if(!this.id){
            this.$message.error('请从正确的链接进来');
            return;
        }
        this.getDetail();
    },
    methods:{
        //  自定义验证
        mainImgValidator(rule, value, callback){
            if(this.mainImgList.length==0){
                callback(new Error('请上传封面图'))
            }else{
                callback()
            }
        },
        imgsValidator(rule, value, callback){
            if(this.imgList.length==0){
                callback(new Error('请上传主图'))
            }else{
                callback()
            }
        },
        detailValidator(rule, value, callback){
            if(this.form.detail.length==0){
                callback(new Error('请输入详情'))
            }else{
                callback()
            }
        },
        priceValidator(rule, value, callback){
            if(this.skuType==1&&!this.price){
                callback(new Error('请输入价格'))
            }else{
                callback()
            }
        },
        quantityValidator(rule, value, callback){
            if(this.skuType==1&&!this.quantity){
                callback(new Error('请输入库存'))
            }else{
                callback()
            }
        },
        oriPriceValidator(rule, value, callback){
            if(this.skuType==1&&!this.oriPrice){
                callback(new Error('请输入原价格'))
            }else{
                callback()
            }
        },
        skuInfoValidator(rule, value, callback){
            if(this.skuType==2&&this.form.skuInfo.length==0){
                callback(new Error('请填写多规格数据'))
            }else{
                callback()
            }
        },
        skuListValidator(rule, value, callback){
            if(this.skuType==2&&this.form.skuList.length==0){
                callback(new Error('请填写多规格明细'))
            }else{
                callback()
            }
        },
        expressValidator(rule, value, callback){
            if(!this.form.delivery.isPostFree&&!this.form.delivery.deliveryInfo.express.price){
                callback(new Error('请填写运费'))
            }else{
                callback()
            }
        },
        // =================================

        async getDetail(){
            const rs = await this.$https.cloud({
                action:'goods',
                type:'detail',
                formData:{
                    _id: this.id
                }
            });
            if(rs.code!=0) retrun;
            let data = rs.data.data[0];
            SkuList = utils.clone(data.skuList);

            this.form = data;
 
            // const loading = this.$loading({
            //     text:'图片加载中...'
            // });
            try {
                await this.detailCb(data);
                // loading.close()
            } catch (error) {
                // loading.close()
            }
            
        },
        async detailCb(data){
            if(data.skuInfo.length>0) {
                //  多规格
                this.skuType = 2;
                data = await this.changeSkuInfo(data);
            }else{
                this.price = data.skuList[0].price;
                this.quantity = data.skuList[0].quantity;
                this.oriPrice = data.skuList[0].oriPrice;
                this.productCode = data.skuList[0].productCode;
                if(data.skuList[0].iconUrl){

                    this.iconUrl.fileid = data.skuList[0].iconUrl;
                    const rs = await this.$https.getImagePath([{fileid:data.skuList[0].iconUrl}]);
                    this.iconUrl.thumb = rs[0].download_url;
                }
                
                this.skuType = 1;

            }
            //  转换各种图片
            if(data.mainImg){

                const mainImg = await this.$https.getImagePath([{fileid: data.mainImg}]);
                this.mainImgList = [{
                    url: mainImg[0].download_url,
                    fileid: mainImg[0].fileid
                }]
            }
            if(data.img.length>0){

                const imgList = [];
                data.img.forEach(item=>{
                    imgList.push({fileid: item});
                });
                const list = await this.$https.getImagePath(imgList);
                list.forEach((item,i)=>{
                    imgList[i] = {
                        url: item.download_url,
                        fileid: item.fileid
                    }
                });
                this.imgList = imgList;
            }

            let detail = [];
            for(let i=0;i<data.detail.length;i++){
                if(data.detail[i].img){
                    const img = await this.$https.getImagePath([{fileid: data.detail[i].img}]);
                    detail.push({img: {
                        img: img[0].download_url,
                        fileid: img[0].fileid
                    }})
                }else{
                    detail.push(data.detail[i]);
                }
            }
            data.detail = detail;
            
            this.form = data;
            setTimeout(()=>{
                console.log(SkuList)
                this.$refs.skulist.setSkuList(SkuList);
            },500)
        },
        async changeSkuInfo(data){
            let skuInfo = [];
            data.skuInfo.forEach(item=>{
                let k =  Object.keys(item)[0];
                skuInfo.push({
                    key: k,
                    input:'',
                    value: item[k]
                })
               
            })
            
            let skuThumb = [];
            data.skuList.forEach(item=>{
                if(item.iconUrl){
                    skuThumb.push({fileid: item.iconUrl})
                }
            });
            let map = new Map();
            skuThumb = skuThumb.filter(item=>!map.has(item.fileid)&&map.set(item.fileid,1));
            if(skuThumb.length==0 || !skuThumb) {
                skuInfo[0].thumb = [];
                data.skuInfo = skuInfo;
                return data;
            }
            const imgs = await this.$https.getImagePath(skuThumb);
            const fileids = [];
            imgs.forEach((item,i)=>{
                skuThumb[i].thumb = item.download_url;
                fileids.push(item.fileid);
            });
            skuInfo[0].thumb = fileids;
            data.skuInfo = skuInfo;
            this.$nextTick(()=>{
                console.log(this.$refs.skuform);
                this.$refs.skuform.setSkuThumb(skuThumb);
            })
            return data;
        },
        beforeUploadHandler(file){
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
            this.$message.error('上传图片大小不能超过 3MB!');
            }
            return isLt3M;
        },
        //  修改多规格值回调函数
        skuInfoChangeHandler(data){
            this.form.skuInfo = data;
        },
         //  统一规格的上传图片
        async thumbUploadHandler(e){
            const file = e.target.files[0];
            const localPath = utils.Img_URL(file);
            const rs = await this.$https.uploadImage('product',file);
            this.iconUrl = {
                thumb: localPath,
                fileid: rs
            }
        },
        //  统一规格的删除图片
        async delThumbHandler(){
            await this.$https.delImage([this.iconUrl.fileid]);
            this.iconUrl = {
                thumb:'',
                fileid:''
            }
        },
        uploadHandler1(file,fileList){
            this.uploadCode(file).then(rs=>{
                this.mainImgList.push(rs);
            });
        },
        uploadHandler2(file,fileList){
            this.uploadCode(file).then(rs=>{
                this.imgList.push(rs);
            })
        },
        async uploadCode(file){
            const localPath = utils.Img_URL(file.file);
            const rs = await this.$https.uploadImage('product',file.file);
            return {
                url: localPath,
                fileid: rs,
                uid: file.file.uid
            };
        },
        handlePictureCardPreview(file){
             this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        handleRemove1(file,fileList){
            this.$https.delImage([this.mainImgList[0].fileid]);
            this.mainImgList = [];
        },
        handleRemove2(file,fileList){
            this.imgList.forEach((item,i)=>{
                if(item.uid == file.uid){
                    let data = this.imgList.splice(i,1);
                    console.log(data);
                    this.$https.delImage([data[0].fileid]);
                }
            })
        },
        expressChangeHandler(e){
            console.log(e);
        },
        saveHandler(){
            this.$refs.form.validate(valid=>{
                console.log(valid)
                if(!valid)return;
                this.saveCode();
            })
        },
        async saveCode(){
            //  保存
            let formData = {};
            formData._id = this.id;
            formData.productName = this.form.productName;
            formData.description = this.form.description;
            formData.status = this.form.status;
            formData.delivery = this.form.delivery;
            formData.skuList = this.form.skuList;
            formData.detail = this.form.detail;
            formData.img = [];
            if(this.skuType==1){
                formData.skuInfo = [];
                formData.skuList = [{
                    iconUrl: this.iconUrl.fileid||'',
                    oriPrice:this.oriPrice,
                    price:this.price,
                    quantity: this.quantity,
                    productCode:this.productCode,
                    sku_id:''
                }];
            }else{

                let skuInfo = [];
                this.form.skuInfo.forEach(item=>{
                    skuInfo.push({[item.key]:item.value})
                });
                formData.skuInfo = skuInfo;
            }
            let detail = [];
            formData.detail.forEach(item=>{
                if(item.img){
                    detail.push({img: item.img.fileid});
                }else{
                    detail.push(item);
                }
            });
            formData.detail = detail;
            formData.mainImg = this.mainImgList[0].fileid;
            this.imgList.forEach(img=>{
                formData.img.push(img.fileid);
            });
            //  如果是免邮，邮费设置为空
            if(formData.delivery.isPostFree){
                formData.delivery.deliveryInfo.price = '';
            }
            for(let i=0;i<formData.skuList.length;i++){
                let sku = formData.skuList[i];
                if(!sku.oriPrice || !sku.price || !sku.quantity){
                    this.$message.error('请仔细检查规格的原价，价格和库存有没有漏填');
                    return;
                }
            }
            console.log(formData);
            console.log(JSON.stringify(formData));
            const rs = await this.$https.cloud({
                action:'goods',
                type:'update',
                formData:formData
            });
            
            if(rs.code==0){
                this.$message.success(rs.msg);
                this.$router.push({name:'goodManage'});
            }else{
                this.$message.error(rs.msg);  
            }
        },

        cancelHandler(){
            this.$router.push({name:'goodManage'})
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';
.addGood-wrapper {
    background: #fff;
    padding: 10px;

    .thumb-upload {
        line-height: 1;
        padding: 5px 15px;
        color: #999;
        border-radius: 2px;
        border: 1px solid #ccc;

        .upload-img {
            width: 100%;
            height: 100%;
            
        }
    }

    .thumb-box {
        .thumb {
            width: 50px;
            height: 50px;
            vertical-align: middle;
        }

        .thumb-del {
            color: $baseColor;
            vertical-align: middle;
        }
    }

    .addGood-title {
        line-height: 50px;
        font-size: 16px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
        
        h1 {
            padding-right: 10px;
        }
    }
    .richText-box {
        width: 500px;
        height: 500px;
    }

    .footer {
        margin-top: 20px;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background: #fff;
    }
}
</style>